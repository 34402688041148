html,
body,
#root {
  margin: 0;
  padding: 0;
  position: relative;
  background: linear-gradient(0deg, #eef1f8, #eef1f8), #f6f9ff;
}

.tbody tr > td {
  padding: 5px !important;
}
